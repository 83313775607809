//  CSS
import "./PreloadPage.scss"
//  App
import LoadingImage from "../../images/loader.svg"


export default function PreloadPage() {

  //  //  //  //  //  //  //  //  //  //  //  Render //  //  //  //  //  //  //  //  //  //  //
  return (
    <div className="preload-page">
      <img
        src={LoadingImage}
        alt="loading"
      />
    </div>
  )
}