//  Dependencies
import CryptoJS from "crypto-js"


export function Encrypt(value) {
  try {
    return CryptoJS.AES.encrypt(value, process.env.REACT_APP_ENCRYPT_KEY).toString()
  } catch (error) {
    return null
  }
}


export function Decrypt(value) {
  try {
    return CryptoJS.AES.decrypt(value, process.env.REACT_APP_ENCRYPT_KEY).toString(CryptoJS.enc.Utf8)
  } catch (error) {
    return null
  }
}