import React, { Component } from "react";
import Logo from '../../../piko/images/bleemlogo.png';
import Loading from "../../../piko/images/loader.svg";

require("./style.scss");

export default class LoadingScreen extends Component {

  getLoadingText(text) {
    return text ?
      <div className="loading-text">{text}</div> : null;
  }

  render() {
    const { text } = this.props;

    return (
      <div className="loading">
        {/* <div><Logo size="large"/></div>                
        {this.getLoadingText(text)} */}
        <div className="container-images">
          <img
            className="logo"
            src={Logo}
            alt="Logo"
          />
          <img
            className="loading-icon"
            src={Loading}
            alt="Loading"
          />
        </div>
      </div>
    );
  }
};
