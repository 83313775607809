//  Dependencies
import { lazy } from "react"
import { Outlet } from "react-router-dom"
//  App
import useAuth from "../../hooks/useAuth"
import LoadingScreen from "../../../react/screens/loading"
const AdminLogin = lazy(() => import("./AdminLogin/AdminLogin"))


export default function AdminLayout() {
  //  //  //  //  //  //  //  //  //  //  //  Values //  //  //  //  //  //  //  //  //  //  //
  const {
    token,
    userInfo,
    loadingPage
  } = useAuth()


  //  //  //  //  //  //  //  //  //  //  //  Render //  //  //  //  //  //  //  //  //  //  //
  if (!(token === "")) {
    if (loadingPage) {
      return <LoadingScreen />
    } else {
      if ((userInfo.rol) && (userInfo.rol === "Admin")) {
        return <Outlet />
      }
    }
  }
  return <AdminLogin />
}