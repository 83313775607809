//  CSS
import "./LoadingCall.scss"
//  App
import useAuth from "../../hooks/useAuth"
import LoadingIcon from "../../images/loader.svg"


export default function LoadingCall() {
  //  //  //  //  //  //  //  //  //  //  //  Values //  //  //  //  //  //  //  //  //  //  //
  const { loadingCall } = useAuth()


  //  //  //  //  //  //  //  //  //  //  //  Render //  //  //  //  //  //  //  //  //  //  //
  if (loadingCall)
    return (
      <div className="loading-call">
        <img
          src={LoadingIcon}
          alt="loading-icon"
        />
      </div>
    )
  return null
}