//  Dependencies
import {
  lazy,
  Suspense
} from "react"
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom"
//  App
import Webrcade from "./react/webrcade"
import PreloadPage from "./piko/pages/PreloadPage/PreloadPage"
import AdminLayout from "./piko/pages/AdminLayout/AdminLayout"
const AdminEdit = lazy(() => (import("./edit/AdminEdit")))
const LoginPage = lazy(() => (import("./piko/pages/LoginPage/LoginPage")))
const NotFoundPage = lazy(() => (import("./piko/pages/NotFoundPage/NotFoundPage")))


export default function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<PreloadPage />}>
        <Routes>


          <Route
            path="/"
            element={<Webrcade />}
          />
          <Route
            path="login"
            element={<LoginPage />}
          />


          <Route
            path="admin"
            element={<AdminLayout />}
          >
            <Route
              index={true}
              element={<AdminEdit />}
            />
          </Route>


          <Route
            path="*"
            element={<NotFoundPage />}
          />


        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}