import React from "react";
import ReactDOM from "react-dom";
import '@webrcade/app-common/dist/index.css'

import {
  preloadImages,
} from '@webrcade/app-common'

import App from "./App";
import { AuthContextPorvider } from "./piko/hooks/useAuth";

preloadImages([
  "images/default-thumb.png",
]);

ReactDOM.render(
  <React.StrictMode>
    <AuthContextPorvider>
      <App />
    </AuthContextPorvider>
  </React.StrictMode>,
  document.getElementById("root")
);