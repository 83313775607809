//  Dependencies
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react"
import axios from "axios"
//  App
import axiosClient from "../../piko/helpers/axiosClient"
import {
  Encrypt,
  Decrypt
} from "../helpers/Encriptation"


export const authContext = createContext(null)


export function AuthContextPorvider({ children }) {
  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  VALUES  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //
  const [token, setToken] = useState(
    (localStorage.getItem("token")) ? localStorage.getItem("token") : ""
  )
  const [userInfo, setUserInfo] = useState({})
  const [loadingCall, setLoadingCall] = useState(false)
  const [loadingPage, setLoadingPage] = useState(false)
  const [errors, setErrors] = useState([])
  const values = useMemo(() => {
    return ({
      // Values info
      token,
      userInfo,
      loadingCall,
      errors,
      loadingPage,
      //  Values Editors
      setLoadingCall,
      setToken,
      //  Functions
      SubmitUser,
      HandleErrors,
      SubmitAdminUser,
      LogOutUser
    })
  }, [
    token,
    userInfo,
    loadingCall,
    errors,
    loadingPage
  ])


  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  FUNCTIONS  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //
  function HandleErrors(errors) {
    setErrors(errors)

    setTimeout(() => {
      setErrors([])
    }, 3500);
  }

  async function SubmitUser(data) {
    setLoadingCall(true)

    try {
      const result = await axios.post(`${process.env.REACT_APP_WIX}login`, data, {
        headers: {
          key: process.env.REACT_APP_WIX_KEY
        }
      })
        .then((response) => response.data)

      setToken(Encrypt(result.userToken))
      localStorage.setItem("token", Encrypt(result.userToken))

      setTimeout(() => {
        setLoadingCall(false)
      }, 100)

      window.location.href = window.location.href
    } catch (error) {
      // console.log(error)

      if (error.response) {
        HandleErrors([error.response.data.Error])
      } else {
        HandleErrors([error.message])
      }

      setTimeout(() => {
        setLoadingCall(false)
      }, 100)
    }
  }

  async function SubmitAdminUser(data) {
    setLoadingCall(true)

    try {
      const result = await axiosClient.post("login", data)
        .then((response) => response.data)

      setToken(Encrypt(result.access_token))
      localStorage.setItem("token", Encrypt(result.access_token))

      setTimeout(() => {
        setLoadingCall(false)
      }, 100)

      window.location.href = window.location.href
    } catch (error) {
      // console.log(error)

      if (error.response) {
        HandleErrors([error.response.data.Error])
      } else {
        HandleErrors([error.message])
      }

      setTimeout(() => {
        setLoadingCall(false)
      }, 100)
    }
  }

  async function LogOutUser() {
    if (Object.keys(userInfo).length > 0) {
      switch (userInfo.rol) {
        case "Admin": {
          setLoadingCall(true)

          try {
            await axiosClient.get("logout", {
              headers: {
                Authorization: `Bearer ${Decrypt(token)}`
              }
            })
              .then((response) => response.data)

            setUserInfo({})
            setToken("")
            localStorage.removeItem("token")
          } catch (error) {
            // console.log(error)

            if (error.response) {
              HandleErrors([error.response.data.Error])
            } else {
              HandleErrors([error.message])
            }
          } finally {
            setTimeout(() => {
              setLoadingCall(false)
            }, 100)

            window.location.href = window.location.href
          }

          break
        }
        default: {
          setLoadingCall(true)

          try {
            await axiosClient.get("logoutWix", {
              headers: {
                Authorization: `Bearer ${Decrypt(token)}`
              }
            })
              .then((response) => response.data)

            setUserInfo({})
            setToken("")
            localStorage.removeItem("token")
          } catch (error) {
            // console.log(error)

            if (error.response) {
              HandleErrors([error.response.data.Error])
            } else {
              HandleErrors([error.message])
            }
          } finally {
            setTimeout(() => {
              setLoadingCall(false)
            }, 100)

            window.location.href = window.location.href
          }

          break
        }
      }
    }
  }

  async function GetUserInfo(controller) {
    if (token !== "") {

      const getToken = Decrypt(token)

      if (getToken.split(".")[0] === "JWS") {
        try {
          setLoadingPage(true)

          const result = await axiosClient.get("infoWix", {
            headers: {
              Authorization: getToken
            },
            signal: controller.signal
          })
            .then((response) => response.data.Response)

          setUserInfo({
            correo: result.email,
            rol: result.suscription
          })

          setTimeout(() => {
            setLoadingPage(false)
          }, 100)
        } catch (error) {
          // console.log(error)

          if (error.response) {
            if (error.response.status === 401) {
              localStorage.removeItem("token")
              setToken("")
            }

            HandleErrors([error.response.data.Error])
          } else {
            HandleErrors([error.message])
          }
        }
      } else {
        try {
          setLoadingPage(true)

          const result = await axiosClient.get("infoUser", {
            headers: {
              Authorization: `Bearer ${getToken}`
            },
            signal: controller.signal
          })
            .then((response) => response.data)

          setUserInfo({
            correo: result.email,
            rol: result.rol
          })

          setTimeout(() => {
            setLoadingPage(false)
          }, 100)
        } catch (error) {
          // console.log(error)

          if (error.response) {
            if (error.response.status === 401) {
              localStorage.removeItem("token")
              setToken("")
            }

            HandleErrors([error.response.data.Error])
          } else {
            HandleErrors([error.message])
          }
        }
      }
    }
  }


  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  useEffect  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //
  useEffect(() => {
    const controller = new AbortController()
    let tokenRefresh = null

    if (Object.keys(userInfo).length === 0) {
      GetUserInfo(controller)
    }

    if (userInfo.rol === "Admin") {
      tokenRefresh = setTimeout(() => {
        localStorage.removeItem("token")
        setToken("")
      }, 10440000);
    }

    return () => {
      controller.abort()
      clearTimeout(tokenRefresh)
      tokenRefresh = null
    }
  }, [token])


  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  RENDER  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //  //
  return (
    <authContext.Provider value={values}>
      {children}
    </authContext.Provider>
  )
}


export default function useAuth() {
  return useContext(authContext)
}