import {
  AppRegistry,
  // GamepadEnum,
  Resources,
  TEXT_IDS,
  PlayArrowBlackImage,
  PlayArrowWhiteImage
} from '@webrcade/app-common'

import * as Session from "./session"
import LoadingImg from "../../../piko/images/loader.svg"

const getAppInfo = (appBrowse, currentItem, userInfo) => {
  const { sliderRef } = appBrowse;
  const { onAppSelected, feed } = appBrowse.props;
  const { category } = appBrowse.state;
  const { ModeEnum } = appBrowse;
  const reg = AppRegistry.instance;


  function button1LabelTextHandle() {
    if (userInfo.token === "") {
      if (currentItem.isFree) {
        return Resources.getText(TEXT_IDS.PLAY_UC);
      }

      return "Get Premium";
    }

    if (Object.keys(userInfo.info).length > 0) {
      switch (userInfo.info.rol) {
        case "SUPER_MEMBER": {
          return Resources.getText(TEXT_IDS.PLAY_UC);
        }
        case "Admin": {
          return Resources.getText(TEXT_IDS.PLAY_UC);
        }
        case "MEMBER": {
          if (currentItem.isFree) {
            return Resources.getText(TEXT_IDS.PLAY_UC);
          }

          return "Get Premium";
        }
        default: {
          if (currentItem.isFree) {
            return Resources.getText(TEXT_IDS.PLAY_UC);
          }

          return "Get Premium";
        }
      }
    }

    return "";
  }
  function button1ImgHandle() {
    if (userInfo.token === "") {
      if (currentItem.isFree) {
        return PlayArrowBlackImage;
      }

      return "";
    }

    if (Object.keys(userInfo.info).length > 0) {
      switch (userInfo.info.rol) {
        case "SUPER_MEMBER": {
          return PlayArrowBlackImage;
        }
        case "Admin": {
          return PlayArrowBlackImage;
        }
        case "MEMBER": {
          if (currentItem.isFree) {
            return PlayArrowBlackImage;
          }

          return "";
        }
        default: {
          if (currentItem.isFree) {
            return PlayArrowBlackImage;
          }

          return "";
        }
      }
    }

    return LoadingImg;
  }


  return {
    title: reg.getLongTitle(currentItem),
    subTitle: reg.getName(currentItem),
    description: reg.getDescription(currentItem),
    backgroundSrc: reg.getBackground(currentItem),
    backgroundPixelated: currentItem.backgroundPixelated,
    defaultBackgroundSrc: reg.getDefaultBackground(currentItem),
    categoryLabel: appBrowse.getLongTitle(category),
    // flyoutLabel: Resources.getText(TEXT_IDS.SHOW_CATEGORIES),
    button1Label: button1LabelTextHandle(),
    button1Img: button1ImgHandle(),
    button1HoverImg: PlayArrowWhiteImage,
    getTitle: item => reg.getTitle(item),
    getThumbnailSrc: item => reg.getThumbnail(item),
    getDefaultThumbnailSrc: item => reg.getDefaultThumbnail(item),
    onClick: () => {
      if (userInfo.token === "") {
        if (currentItem.isFree) {
          if (onAppSelected) onAppSelected(currentItem);
          Session.setLastItem(feed.getTitle(), category.title, currentItem.title);
          return;
        }

        window.open("https://jesus529.wixsite.com/my-site/plans-pricing");
        return;
      }

      if (Object.keys(userInfo.info).length > 0) {
        switch (userInfo.info.rol) {
          case "SUPER_MEMBER": {
            if (onAppSelected) onAppSelected(currentItem);
            Session.setLastItem(feed.getTitle(), category.title, currentItem.title);
            break;
          }
          case "Admin": {
            if (onAppSelected) onAppSelected(currentItem);
            Session.setLastItem(feed.getTitle(), category.title, currentItem.title);
            break;
          }
          case "MEMBER": {
            if (currentItem.isFree) {
              if (onAppSelected) onAppSelected(currentItem);
              Session.setLastItem(feed.getTitle(), category.title, currentItem.title);
              break;
            }

            window.open("https://jesus529.wixsite.com/my-site/plans-pricing");
            break;
          }
          default: {
            if (currentItem.isFree) {
              if (onAppSelected) onAppSelected(currentItem);
              Session.setLastItem(feed.getTitle(), category.title, currentItem.title);
              break;
            }

            window.open("https://jesus529.wixsite.com/my-site/plans-pricing");
            break;
          }
        }
      }


      // if (onAppSelected) onAppSelected(currentItem);
      // Session.setLastItem(feed.getTitle(), category.title, currentItem.title);
    },
    categoryOnClick: () => {
      appBrowse.setState({
        currentItem: feed.getCategories()[0],
        menuMode: ModeEnum.CATEGORIES
      }, () => { setTimeout(() => { Session.clearLastItem(); }, 0) });
      sliderRef.current.focus();
    }
  }
}

export default getAppInfo; 