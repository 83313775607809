import React, { Component } from "react";

import {
  settings,
  ImageButton
} from '@webrcade/app-common'

import SettingsYellowImage from "../../../../piko/images/settings_yellow_24dp.svg"
import ChevronLeftBlackImage from "../../../../piko/images/chevron_left_black_24dp.svg"

import TextScroll from "../../../components/textscroll"
import { Link } from "react-router-dom";
import LoadingImg from "../../../../piko/images/loader.svg";

require("./style.scss");

export default class AppDetails extends Component {
  timeoutId = null;
  lastKey = null;

  constructor() {
    super();

    this.optionMovilRef = React.createRef();
  }

  handleOptionsMovileClick = (event) => {
    if (this.optionMovilRef.current && !this.optionMovilRef.current.contains(event.target)) {
      this.optionMovilRef.current.classList.remove("show");
      this.optionMovilRef.current.classList.add("hide");
    }
  }

  componentDidMount() {
    document.addEventListener('touchstart', (event) => this.handleOptionsMovileClick(event));
  }
  componentWillUnmount() {
    document.addEventListener('touchstart', (event) => this.handleOptionsMovileClick(event));
  }

  render() {
    const { backgroundSrc, defaultBackgroundSrc, bottom, buttons,
      description, disable, focusGrid, itemKey, onSettings, subTitle,
      title, pixelated, settingsButtonRef, goBack, userInfo, logOutUser } = this.props;
    const key = itemKey;
    const detailsRightRef = this.detailsRightRef;
    let showOptionsMovile = false;

    if ((key !== this.lastKey) && detailsRightRef) {
      this.lastKey = key;
      const WAIT = 250;
      const start = new Date().getTime();

      if (this.timeoutId) window.clearTimeout(this.timeoutId);

      // Remove display of right details
      detailsRightRef.classList.remove('fade-in');
      detailsRightRef.style.backgroundImage = 'none';

      // Common fade in
      const fadeIn = () => {
        const elapsed = new Date().getTime() - start;
        const wait = (elapsed > WAIT ? 0 : (WAIT - elapsed));
        this.timeoutId = window.setTimeout(() => {
          if (key === this.lastKey) {
            detailsRightRef.classList.add('fade-in');
          }
        }, wait);
      }

      const displayBackground = (src) => {
        if (key === this.lastKey) {
          detailsRightRef.style.backgroundImage = "url(\"" + src + "\")";
          fadeIn();
        }
      }

      // Attempt to load the background image
      const img = new Image();
      img.onload = () => { displayBackground(img.src); };
      img.onerror = () => {
        // If an error occurred, attempt to load default background
        const defaultImg = new Image();
        defaultImg.onload = () => { displayBackground(defaultImg.src); };
        defaultImg.src = defaultBackgroundSrc;
      }
      img.src = backgroundSrc;
    }

    const rightClass =
      "app-details-right" + (pixelated ? " app-details-right--pixelated" : "")

    const headerNavStyles = {}
    if (settings.getHideTitleBar()) {
      headerNavStyles['display'] = 'block';
    }

    return (
      <div className="app-details-content">
        <div className="app-details-background">
          <div className="app-details-left"></div>
          {!disable ? (
            <div className="details-header-nav" style={headerNavStyles}>
              <div className="info-user-movile">
                {(userInfo.token === "") ?
                  <Link to="/login">
                    Log In
                  </Link> :
                  (Object.keys(userInfo.info).length > 0) ?
                    <>
                      <button
                        className="info-user-movile-head"
                        onClick={() => {
                          showOptionsMovile = this.optionMovilRef.current.classList.contains("hide");

                          if (showOptionsMovile) {
                            this.optionMovilRef.current.classList.remove("hide");
                            this.optionMovilRef.current.classList.add("show");
                          }
                        }}
                      >
                        {userInfo.info.correo}
                      </button>
                      <div
                        className="info-user-movile-options hide"
                        ref={this.optionMovilRef}
                      >
                        <button onClick={logOutUser}>
                          Log out
                        </button>
                      </div>
                    </> :
                    <img
                      className="info-user-movile-loading"
                      src={LoadingImg}
                      alt="loading-img"
                    />
                }
              </div>
              <ImageButton
                className={"details-header-nav-button"}
                onPad={e => focusGrid.moveFocus(e.type, settingsButtonRef)}
                onClick={() => onSettings()}
                ref={settingsButtonRef}
                imgSrc={SettingsYellowImage}
              />
            </div>
          ) : null}
          <div ref={(detailsRight) => { this.detailsRightRef = detailsRight; }} className={rightClass}>
          </div>
        </div>
        <div className="app-details-content-container">
          {(goBack) ?
            <div>
              <ImageButton
                className={"negative-button"}
                imgSrc={ChevronLeftBlackImage}
                onClick={goBack}
                label="BACK"
              />
            </div>
            : null
          }
          <div className="app-details-content-container-title">{title ? title : ''}</div>
          {subTitle ? (
            <div className="app-details-content-container-subtitle">{subTitle}</div>
          ) : null}
          {description ? (
            <div className="app-details-content-container-description">
              <TextScroll key={itemKey} text={description} />
            </div>
          ) : null}
          <div className="app-details-content-container-buttons">{buttons}</div>
          <div className="app-details-content-container-bottom-comp">{bottom}</div>
        </div>
      </div>
    );
  }
};
